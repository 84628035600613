import React, {
  MouseEventHandler,
  useReducer,
  useState,
  useEffect,
} from "react";
import "./App.css";
import TileGrid from "./components/TileGrid/index";
import WordList from "./components/WordList/index";
import Message from "./components/Message/index";
import Instructions from "./components/Instructions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import InfoBox from "./components/InfoBox";
import reducer, {
  type State,
  type DispatchArgs,
  selectedTilesFormWord,
} from "./gameReducer";
import { game } from "./constants";

export const GameContext = React.createContext<null | {
  state: State;
  dispatch: (args: DispatchArgs) => void;
}>(null);

const tierMessages = [
  "Starting out",
  "Nice",
  "Great",
  "Excellent",
  "Fantastic",
  "Phenomenal",
];

const lightNumber = "48";
const darkNumber = "242";

const tierStyles = [
  {
    start: `rgb(${darkNumber},${lightNumber},${darkNumber})`,
    end: `rgb(${lightNumber},${lightNumber},${darkNumber})`,
  },
  {
    start: `rgb(${lightNumber},${lightNumber},${darkNumber})`,
    end: `rgb(${lightNumber},${darkNumber},${darkNumber})`,
  },
  {
    start: `rgb(${lightNumber},${darkNumber},${darkNumber})`,
    end: `rgb(${lightNumber},${darkNumber},${lightNumber})`,
  },
  {
    start: `rgb(${lightNumber},${darkNumber},${lightNumber})`,
    end: `rgb(${darkNumber},${darkNumber},${lightNumber})`,
  },
  {
    start: `rgb(${darkNumber},${darkNumber},${lightNumber})`,
    end: `rgb(${darkNumber},${lightNumber},${lightNumber})`,
  },
  {
    start: `rgb(${darkNumber},${lightNumber},${lightNumber})`,
    end: `rgb(${darkNumber},${lightNumber},${darkNumber})`,
  },
];

function App() {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [showScoreBox, setShowScoreBox] = useState(false);
  const [gameReady, setGameReady] = useState(false);
  const [showError, setShowError] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    lettersMatrix: [],
    startingLettersMatrix: [],
    selectedTiles: [],
    usedWords: [],
    usedTiles: 0,
    message: "",
    finishedGame: false,
    scoreTiers: [],
    score: 0,
    scoreTier: 0,
  });
  const [showGameEnd, setShowGameEnd] = useState(state.finishedGame);

  useEffect(() => {
    if (state.finishedGame) {
      setShowGameEnd(true);
    }
  }, [state.finishedGame, state.lettersMatrix]);

  useEffect(() => {
    const r = document.querySelector(".wrapper") as HTMLElement;
    r.style.setProperty(
      "--gradient-start",
      tierStyles[state.scoreTier % 6].start
    );
    r.style.setProperty("--gradient-end", tierStyles[state.scoreTier % 6].end);
  }, [state.scoreTier]);

  const handleGameReset = () => {
    dispatch({type: "RESET_GAME"})
    setShowGameEnd(false);
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      dispatch({
        type: "SET_GAME",
        lettersMatrix: game.matrix,
        scoreTiers: game.scoreTiers,
      });
      setGameReady(true);
    } else {
      const today = new Date();
      const UTCDate = today.getUTCDate();
      const UTCMonth = today.getUTCMonth() + 1;
      const UTCYear = today.getUTCFullYear();
      fetch(
        `https://downwords.blob.core.windows.net/downwords/${UTCYear}-${String(
          UTCMonth
        ).padStart(2, "0")}-${String(UTCDate).padStart(2, "0")}.json`
      )
        .then((response) => response.json())
        .then((json) => {
          dispatch({
            type: "SET_GAME",
            lettersMatrix: json.matrix,
            scoreTiers: json.scoreTiers,
          });
          setGameReady(true);
        })
        .catch(() => {
          setShowError(true);
        });
    }
  }, []);

  const handleSubmit: MouseEventHandler = () => {
    const { valid, message } = selectedTilesFormWord(state);
    if (valid) {
      dispatch({ type: "INCREMENT_SELECTED_TILE_USES" });
    } else dispatch({ type: "RESET_SELECTED_TILES", message });
  };

  const handleInfoClick: MouseEventHandler = () => {
    setShowInfoBox(!showInfoBox);
  };

  const handleScoreClick: MouseEventHandler = () => {
    setShowScoreBox(!showScoreBox);
  };

  const handleGameEndToggle: MouseEventHandler = () => {
    setShowGameEnd(!showGameEnd);
  };

  const copyGameScore = () => {
    const today = new Date();
    const UTCDate = today.getUTCDate();
    const UTCMonth = today.getUTCMonth() + 1;
    const UTCYear = today.getUTCFullYear();
    navigator.clipboard.writeText(`Downwords - ${UTCYear}-${String(UTCMonth).padStart(2, "0")}-${String(UTCDate).padStart(2, "0")}\nScore: ${state.score} \nTier: ${state.scoreTier} \nTiles Removed: ${state.usedTiles} \nWords Found: ${state.usedWords.length}`)
  }

  return (
    <div className="wrapper">
      <ul className="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      {gameReady ? (
        <GameContext.Provider value={{ state, dispatch }}>
          <div className="user-controls">
            <div className="title-container">
              <h1>{tierMessages[state.scoreTier]}</h1>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                size={"2x"}
                className="cursor-pointer"
                onClick={handleInfoClick}
                title="Game Information"
              />
            </div>
            <Message></Message>
          </div>
          <div className="game-container">
            <TileGrid />
            <div className="button-group">
              <button className="primary" onClick={handleScoreClick}>
                Score
              </button>
              <button className="primary" onClick={handleSubmit}>
                Play Word
              </button>
            </div>
          </div>
          <InfoBox
            onToggleVisibility={handleInfoClick}
            show={showInfoBox}
            title="Instructions"
          >
            <Instructions onClose={handleInfoClick} />
          </InfoBox>

          <InfoBox
            onToggleVisibility={handleScoreClick}
            show={showScoreBox}
            title="Score"
          >
            <div className="tier-container">
                <p>Level: {tierMessages[state.scoreTier]}</p>
                <p>Score: {state.score}</p>
                <br />
                <p>
                  Next Level:{" "}
                  {state.scoreTier === 5
                    ? "At Maximum!"
                    : tierMessages[state.scoreTier + 1]}
                </p>
                <p>
                  Next Tier Score:{" "}
                  {state.scoreTier === 5
                    ? "At Maximum"
                    : state.scoreTiers[state.scoreTier] + 1}
                </p>
            </div>
            <WordList />
          </InfoBox>

          <InfoBox
            onToggleVisibility={handleGameEndToggle}
            show={showGameEnd}
            title="Completed!"
          >
            <div className="game-end-container">
              <div>
                <p>Final Level: {tierMessages[state.scoreTier]}</p>
                <p>Final Score: {state.score}</p>
                <p>Tile Removed: {state.usedTiles}</p>
                <p>Words Found: {state.usedWords.length}</p>

              </div>
              <div className="space-between">
              <button className="primary" onClick={handleGameReset}>Play Again</button>
              <button className="primary" onClick={copyGameScore}>Share</button>
              </div>
            </div>
          </InfoBox>
        </GameContext.Provider>
      ) : (
        showError && (
          <h2 className="error-message">
            Failed to fetch game. Please try refreshing the page.
          </h2>
        )
      )}
    </div>
  );
}

export default App;
