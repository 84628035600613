import { LettersRow, SelectedTile } from "./types";
import { dictionary } from "./constants";
import cloneDeep from "lodash.clonedeep";
import { countWordsInMatrix } from "./utils";

const MAX_TILE_USES = 3;

export interface State {
  lettersMatrix: LettersRow[];
  selectedTiles: SelectedTile[];
  usedWords: string[];
  usedTiles: number;
  message: string;
  finishedGame: boolean;
  score: number;
  scoreTiers: number[];
  scoreTier: number;
  startingLettersMatrix: LettersRow[];
}

export const defaultState: State = {
  lettersMatrix: [],
  startingLettersMatrix: [],
  selectedTiles: [],
  usedWords: [],
  usedTiles: 0,
  message: "",
  finishedGame: false,
  score: 0,
  scoreTiers: [],
  scoreTier: 0,
};

export const tileIsSelected = (
  state: State,
  rowId: number,
  letterId: number
) => {
  const selectedTile = state.selectedTiles[state.selectedTiles.length - 1] as
    | SelectedTile
    | undefined;
  return selectedTile?.letterId === letterId && selectedTile.rowId === rowId;
};

export const tileInHistory = (
  state: State,
  rowId: number,
  letterId: number
) => {
  return state.selectedTiles.some(
    (tile) => tile.letterId === letterId && tile.rowId === rowId
  );
};

export const selectedTilesFormWord = (state: State) => {
  const usedWords = state.usedWords;
  const selectedLetters = state.selectedTiles
    .map((tile) => tile.value)
    .join("")
    .toLowerCase();
  const isWord = dictionary.includes(selectedLetters);
  const isValidWord = dictionary
    .filter((word) => !usedWords.includes(word))
    .includes(selectedLetters);

  if (isWord && !isValidWord)
    return { valid: false, message: "Already Found." };
  else if (isValidWord) return { valid: true };
  else return { valid: false, message: "Invalid Word." };
};

type Actions =
  | "ADD_TILE_SELECTION"
  | "RESET_SELECTED_TILES"
  | "SUBMIT_SELECTED_WORD"
  | "INCREMENT_SELECTED_TILE_USES"
  | "SET_MESSAGE"
  | "SET_GAME"
  | "RESET_GAME"
  | "REMOVE_ROW";

export interface DispatchArgs {
  type: Actions;
  [key: string]: any;
}

const reducer = (state: State, action: DispatchArgs) => {
  switch (action.type) {
    case "SET_GAME":
      const { lettersMatrix, scoreTiers } = action;
      return {
        ...state,
        lettersMatrix,
        startingLettersMatrix: cloneDeep(lettersMatrix),
        scoreTiers,
      };
    case "RESET_GAME":
      return {
        ...state,
        lettersMatrix: cloneDeep(state.startingLettersMatrix),
        score: 0,
        finishedGame: false,
        usedWords: [],
        usedTiles: 0,
        scoreTier: 0,
      }
    case "ADD_TILE_SELECTION":
      const { rowId, letterId, value } = action;
      const currentRowIndex = state.lettersMatrix.findIndex(
        (row) => row.id === rowId
      );

      const selectedTileInNextRow =
        state.lettersMatrix[currentRowIndex - 1]?.id ===
        state.selectedTiles[state.selectedTiles.length - 1]?.rowId;
      const canAddTile = !state.selectedTiles.length || selectedTileInNextRow;

      if (canAddTile)
        return {
          ...state,
          selectedTiles: [...state.selectedTiles, { rowId, letterId, value }],
        };
      else {
        const tileIsSelected = state.selectedTiles.some(
          (tile) => tile.letterId === letterId && tile.rowId === rowId
        );
        if (!tileIsSelected) {
          return {
            ...state,
            selectedTiles: [{ rowId, letterId, value }],
          };
        }
        const selectedTileIndex = state.selectedTiles.findIndex(
          (tile) => tile.letterId === letterId && tile.rowId === rowId
        );
        const newSelectedTiles = state.selectedTiles.slice(
          0,
          selectedTileIndex
        );
        return {
          ...state,
          selectedTiles: newSelectedTiles,
        };
      }

    case "SET_MESSAGE":
      console.log(state.message, action.message)
      if (state.message === action.message) {
        return {
          ...state,
          message: `${action.message} .`,
        }
      } else {
        return {
          ...state,
          message: action.message,
        }
      }

    // Used on failure
    case "RESET_SELECTED_TILES":
      const newState = cloneDeep(state);
      let newMessage = action.message;
      if (state.message === newMessage) newMessage = `${action.message}${" "}`;
      console.log(state.message, action.message, newMessage === action.message)
      newState.lettersMatrix.forEach((row) => {
        row.letters.forEach((letter) => {
          const tileIsSelected = state.selectedTiles.filter(
            (tile) => tile.letterId === letter.id && tile.rowId === row.id
          ).length;
          if (tileIsSelected) letter.jitter = true;
          setTimeout(() => {
            letter.jitter = false;
          }, 500);
        });
      });
      return {
        ...newState,
        selectedTiles: [],
        message: newMessage,
      };

    case "INCREMENT_SELECTED_TILE_USES": {
      const newState = cloneDeep(state);
      let scoreMultiplyer = 1;
      state.selectedTiles.forEach(({ letterId, rowId }) => {
        const newTileRowIndex = newState.lettersMatrix.findIndex(
          (row) => row.id === rowId
        );
        const newTile = newState.lettersMatrix[newTileRowIndex].letters.find(
          (tile) => tile.id === letterId
        );
        if (!newTile) {
          console.error("Failed to find tile while incrememnting tile uses");
          return newState;
        }
        if (newTile.timesUsed === MAX_TILE_USES - 1) {
          newTile.removed = true;
          newState.usedTiles = newState.usedTiles + 1;
          scoreMultiplyer += 1;
        } else newTile.timesUsed = newTile?.timesUsed + 1;
      });
      const word = state.selectedTiles.map((tile) => tile.value).join("");
      const baseScore = 2 ** (word.length - 3);
      const baseWithMultiplyer = baseScore * scoreMultiplyer;
      const wordPoints = baseWithMultiplyer + 5 * (scoreMultiplyer - 1);

      // Check if row is removed now
      const newLetterMatrix = newState.lettersMatrix.filter(
        (row) => {
          return !row.letters.every((letter) => letter.removed);
        }
      );
      
      const remainingWords = countWordsInMatrix(newLetterMatrix, [
        ...state.usedWords,
        word,
      ]);

      const finishedGame = remainingWords.length === 0;
      let scoreTier: number = 0;
      const newScore = state.score + wordPoints;

      for (let i = 0; i <= state.scoreTiers.length; i++) {
        const tierPoints = i === 0 ? 0 : state.scoreTiers[i - 1];
        const nextTierPoints = state.scoreTiers[i];
        if (i === 5) {
          scoreTier = 5;
          break;
        } else if (i === 0 && newScore < tierPoints) {
          scoreTier = 0;
          break;
        } else {
          if (tierPoints < newScore && nextTierPoints >= newScore) {
            scoreTier = i;
            break;
          }
        }
      }

      return {
        ...newState,
        usedWords: [...state.usedWords, word],
        selectedTiles: [],
        message: `${word}, +${wordPoints}!`,
        finishedGame,
        score: newScore,
        scoreTier,
      };
    }

    case "REMOVE_ROW": {
      const newState = cloneDeep(state);
      newState.lettersMatrix = newState.lettersMatrix.filter(
        (row) => row.id !== action.rowId
      );
      return newState;
    }

    default:
      return state;
  }
};

export default reducer;
