import { useContext, useEffect, useState } from "react";
import "./index.css";
import { GameContext } from "../../App";

export default function Message() {
  const context = useContext(GameContext);
  const [show, setShow] = useState(false);
  const { state, dispatch } = context || {};

  useEffect(() => {
    if (state?.message) setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
    // setTimeout(() => {
    //   dispatch && dispatch({ type: "SET_MESSAGE", message: "" });
    // }, 2250);
  }, [state?.message, dispatch]);

  return (
    <div className={`message-wrapper ${show ? "show" : ""}`}>
      <span className="message-container">{state?.message}</span>
    </div>
  );
}
