import { MouseEventHandler, useContext } from "react";
import { Letter } from "../../types";
import { tileInHistory, tileIsSelected } from "../../gameReducer";
import "./tile.css";
import { GameContext } from "../../App";

interface IProps {
  letter: Letter;
  rowId: number;
}

// const tileColors = ["white", "red", "blue", "green"];

export default function Tile({ letter, rowId }: IProps) {
  const context = useContext(GameContext);
  if (!context) return null;

  const { state, dispatch } = context;

  const clickHandler: MouseEventHandler = (e) => {
    dispatch({
      type: "ADD_TILE_SELECTION",
      rowId,
      letterId: letter.id,
      value: letter.value,
    });
  };

  let className = "tile";

  if (letter.removed) className += " removed";

  const selectableRowId =
    state.selectedTiles[state.selectedTiles.length - 1]?.rowId + 1;

  const isSelectable = rowId === selectableRowId;

  if (tileIsSelected(state, rowId, letter.id)) className += " selected";
  else if (tileInHistory(state, rowId, letter.id))
    className += " previous-selection";

  if (letter.timesUsed === 1) className += " used-once";

  if (letter.timesUsed === 2) className += " used-twice";

  if (letter.jitter) className += " jitter";

  if (isSelectable) className += " selectable";

  return (
    <div className={className} onClick={clickHandler}>
      {letter.value}
    </div>
  );
}
