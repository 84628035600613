import { MouseEventHandler, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./index.css";

interface IProps {
  children: any;
  onToggleVisibility: MouseEventHandler;
  show: boolean;
  title: string;
}

export default function Message({
  children,
  onToggleVisibility,
  show,
  title,
}: IProps) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setHidden(true);
      }, 150);
    } else setHidden(false);
  }, [show]);

  const visibilityClasses = `${show ? "" : "hidden"} ${
    hidden ? "z-index-back" : ""
  }`;

  return (
    <div
      className={`info-box-background ${visibilityClasses}`}
      onClick={onToggleVisibility}
    >
      <div className={"info-box"} onClick={(e) => e.stopPropagation()}>
        <div className="info-box-header">
          <h2>{title}</h2>
          <FontAwesomeIcon
            icon={faClose}
            className="cursor-pointer"
            title="close"
            size="2x"
            onClick={onToggleVisibility}
          />
        </div>
        <div className="info-box-content">{children}</div>
      </div>
    </div>
  );
}
