import { useContext } from "react";
import "./index.css";
import { GameContext } from "../../App";

export default function WordList() {
  const context = useContext(GameContext);

  if (!context) return null;
  const { state } = context;

  const allWords = state.usedWords
    .sort()
    .map((word) => <li key={word}>{`${word}`}&nbsp;</li>)

  return (
    <>
      <h3>Found Words</h3>
      <hr />
      <div className="word-container">
        <div className="word-list-container full-width">
          <ul className="word-list listed">{allWords}</ul>
        </div>
      </div>
    </>
  );
}
