import { useContext } from "react";
import Tile from "../Tile";
import "./index.css";
import { Letter } from "../../types";
import { GameContext } from "../../App";

interface IProps {
  letters: Letter[];
  id: number;
}

export default function TileRow({ letters, id }: IProps) {
  const context = useContext(GameContext);
  if (!context) return null;

  let classNames = "tile-row";

  const noLettersRemaining = letters.every((letter) => letter.removed);
  if (noLettersRemaining) {
    classNames += " removed";
    setTimeout(() => {
      context.dispatch({
        type: "REMOVE_ROW",
        rowId: id,
      });
    }, 500);
  }

  return (
    <div className={classNames}>
      {letters.map((letter) => (
        <Tile letter={letter} key={letter.id} rowId={id}></Tile>
      ))}
    </div>
  );
}
