import { LettersRow } from "./types";
import { dictionary } from "./constants";

export const countWordsInMatrix = (
  matrix: LettersRow[],
  usedWords: string[]
) => {
  const words: string[] = [];
  matrix.forEach((row, i) => {
    row.letters
      .filter((letter) => !letter.removed)
      .forEach((letter) => {
        const wordsStartingWithLetter = dictionary.filter((word) =>
          word.startsWith(letter.value)
        );
        wordsStartingWithLetter.forEach((word) => {
          let hasFullWord = true;
          word
            .slice(1)
            .split("")
            .forEach((letter, j) => {
              const nextRowHasNextLetter = matrix[i + j + 1]?.letters
                .filter((letter) => !letter.removed)
                .map((letter) => letter.value)
                .includes(letter);
              if (!nextRowHasNextLetter) hasFullWord = false;
            });
          if (
            hasFullWord &&
            !usedWords.includes(word) &&
            !words.includes(word)
          ) {
            words.push(word);
          }
        });
      });
  });
  return words;
};
