import { useContext } from "react";
import TileRow from "../TileRow/index";
import "./index.css";
import { GameContext } from "../../App";

export default function TileGrid() {
  const context = useContext(GameContext);
  if (!context) return null;

  const { state } = context;

  return (
    <>
      <div className="tile-grid">
        {state.lettersMatrix.map((row) => (
          <TileRow letters={row.letters} key={row.id} id={row.id} />
        ))}
      </div>
    </>
  );
}
