import { MouseEventHandler, useState } from "react";
import "./index.css";

interface IProps {
  onClose: MouseEventHandler;
}

export default function Message({ onClose }: IProps) {
  const [page, setPage] = useState(0);

  const handlePaginationRightClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setPage(page === 1 ? 1 : page + 1);
  };

  const handleClose: MouseEventHandler = (e) => {
    onClose(e);
    setTimeout(() => {
      setPage(0);
    }, 250);
  };

  return (
    <>
      <div className="instruction-page">
        {page === 0 && (
          <div className="page">
            <div>
              <p>
                To play, select tiles from the grid to form words. Once you have selected a tile, the next tile must come from the row immediately below it. Hit the <strong>Play Word</strong> button to submit your selected word. Words must be at least 3 letters long.
              </p>
              <p>
                A tile can be used up to three times. On its third use, it will be removed from the grid. This will make the played word score higher.
              </p>
            </div>
            <button onClick={handlePaginationRightClick} className="primary">Next</button>
          </div>
        )}
        {page === 1 && (
          <div className="page">
            <div>
              <p>
                Words may only be used one time. Click the <strong>Score</strong> button to see the words you have already played.
              </p>
              <p>
                <strong>Scoring:</strong> 
                <ul>
                  <li>3 letters: 1 Point</li>
                  <li>4 letters: 2 Points</li>
                  <li>5 letters: 4 Points</li>
                  <li>6 letters: 8 Points</li>
                  <li>7 letters: 16 Points</li>
                </ul>
              </p>
              <p>In addition, each removed tile is worth 5 points, and adds a +1 multiplier to the word's score.</p>
            </div>
            <button onClick={handleClose} className="primary">Begin!</button>
          </div>
        )}
      </div>
    </>
  );
}
